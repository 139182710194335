import {useCallback, useEffect, useState} from "react";
import { useMsal } from "@azure/msal-react";
import { getRoles, getToken } from "./auth/authConfig";
import { Grid} from "@lego/klik-ui";
import { Menu } from "./components/Menu";
import { DeviceList } from "./pages/DeviceList";
import { GroupList } from "./pages/GroupList";
import {Group} from "./models/Group";
import {Groups} from "./services/Groups";
import {CombinedGroup} from "./models/CombinedGroup";

function App() {
    const { instance } = useMsal();

    const [roles, setRoles] = useState<string[]>([]);
    const [selectedDevice, setSelectedDevice] = useState<(string | number)[]>([]);
    const [isDeviceMembership, setIsDeviceMembership] = useState<boolean>(false);
    const [deviceGroups, setDeviceGroups] = useState<CombinedGroup[]>([]);
    
    const handleDeviceGroups = (newSelectedDevice: string[]) => {
        if (isDeviceMembership && selectedDevice.length < 2) {
            getToken(instance).then(token => {
                const appService = new Groups();
                appService.getDeviceGroups(token.accessToken, newSelectedDevice[newSelectedDevice.length-1]).then((groups: CombinedGroup[]) => {
                    setDeviceGroups(groups);
                })
            })
        }
    }
    
    const handleDeviceMembership = () => {
        setIsDeviceMembership(prevState => !prevState);
        setDeviceGroups([]);
        if (!isDeviceMembership)
            resetDeviceSelection();
    }

    const handleSelectedDevices = useCallback((newSelectedDevice: string[])=>{
        if (isDeviceMembership && newSelectedDevice.length > 0) {
            setSelectedDevice([newSelectedDevice[newSelectedDevice.length - 1]]);
            handleDeviceGroups(newSelectedDevice);
        } else {
            setSelectedDevice(newSelectedDevice);
            setDeviceGroups([]);
        }
    }, [selectedDevice, deviceGroups, isDeviceMembership]);
    
    // const handleSelectedDevices = (newSelectedDevice: string[]) => {
    //     if (isDeviceMembership) {
    //         setSelectedDevice([newSelectedDevice[newSelectedDevice.length - 1]]);
    //         handleDeviceGroups();
    //     } else {
    //         setSelectedDevice(newSelectedDevice);
    //     }
    // };

    const resetDeviceSelection = () => {
      setSelectedDevice([]);
      setDeviceGroups([])
    };
    
    useEffect(() => {
      getToken(instance).then(token => {
        setRoles(getRoles(token.accessToken));
      });
    }, [instance]);

    return (
      <>
        <Menu />
          <Grid className="main-content" templateColumns="1fr 3fr">
              <DeviceList selectedDevice={selectedDevice} handleSelectedDevices={handleSelectedDevices}
                          resetDeviceSelection={resetDeviceSelection} isDeviceMembership={isDeviceMembership}
                          handleDeviceMembership={handleDeviceMembership}/>
              <GroupList selectedDevice={selectedDevice} isDeviceMembership={isDeviceMembership} deviceGroups={deviceGroups}/>
          </Grid>
      </>
    );
}

export default App;