import { Checkbox, Flex, Switch, Table, Tag, Text, Tooltip } from "@lego/klik-ui";
import React, { useEffect, useMemo, useState } from "react";
import { CombinedGroup } from "../models/CombinedGroup";
import { CombinedChecksState } from "../models/CombinedChecksState";

interface GroupRowProps {
    group: CombinedGroup;
    combinedChecks: CombinedChecksState;
    handleCheckedGroup: (checks: CombinedChecksState, group: CombinedGroup) => void;
    forceUpdate: boolean;
    handleForceUpdate: () => void;
}

export const GroupRow = ({ group, combinedChecks, handleCheckedGroup, forceUpdate, handleForceUpdate }: GroupRowProps) => {
    const [requiredChecked, setRequiredChecked] = useState<boolean>(combinedChecks.required);
    const [addChecked, setAddChecked] = useState<boolean>(combinedChecks.add);
    const [removeChecked, setRemoveChecked] = useState<boolean>(combinedChecks.remove);
    const [color, setColor] = useState<string>(combinedChecks.color ||'transparent');


    const handleClickCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        setCombinedChecksData(name, checked, (newState) => {
            handleCheckedGroup(newState, group);
        });
    };
    
    
    const setCombinedChecksData = (name: string, checked: boolean, callback: (newState: CombinedChecksState) => void) => {
        setColor(prevColor => {
            let newColor = prevColor;
            if (checked || name === 'required') {
                switch (name) {
                    case 'required':
                        newColor = '#E8F7E6';
                        break;
                    case 'add':
                        newColor = '#E8F7E6';
                        break;
                    case 'remove':
                        newColor = '#FFF2F2';
                        break;
                }
            } else {
                newColor = 'transparent';
            }
            return newColor;
        });

        setRequiredChecked(prev => {
            const newRequiredChecked = name === 'required' ? checked : prev;
            setAddChecked(prev => {
                const newAddChecked = name === 'add' ? checked : prev;
                setRemoveChecked(prev => {
                    const newRemoveChecked = name === 'remove' ? checked : prev;
                    const newState: CombinedChecksState = {
                        required: newRequiredChecked,
                        add: newAddChecked,
                        remove: newRemoveChecked,
                        color: color,
                        availableId: group.availableId,
                        requiredId: group.requiredId
                    };
                    callback(newState);
                    return newRemoveChecked;
                });
                return newAddChecked;
            });
            return newRequiredChecked;
        });
    };

    useEffect(() => {
        if (forceUpdate) {
            setRequiredChecked(combinedChecks.required);
            setAddChecked(combinedChecks.add);
            setRemoveChecked(combinedChecks.remove);
            setColor(combinedChecks.color || 'transparent');
            handleForceUpdate();
        }
        
    }, [combinedChecks]);
    return (
            <Table.Body>
                <Table.Row style={{ backgroundColor: color }}>
                    <Table.Cell w="35%">{group.name}</Table.Cell>
                    <Table.Cell w="45%">{group.name}</Table.Cell>
                    <Table.Cell w="10%" textAlign="center">
                        <Flex alignItems="center" justifyContent="center" height="100%">
                            <Tooltip aria-label="Available" label="Available" placement="left">
                                <Tag mx="1">
                                    <Tag.Label>A</Tag.Label>
                                </Tag>
                            </Tooltip>
                            <Switch
                                name="required"
                                aria-label="required"
                                colorScheme="warning"
                                defaultChecked={true}
                                isChecked={requiredChecked}
                                isDisabled={!addChecked}
                                onChange={handleClickCheck}
                            />
                            <Tooltip aria-label="Required" label="Required" placement="right">
                                <Tag mx="1">
                                    <Tag.Label>R</Tag.Label>
                                </Tag>
                            </Tooltip>
                        </Flex>
                    </Table.Cell>
                    <Table.Cell w="5%" textAlign="center">
                        <Flex alignItems="center" justifyContent="center" height="100%">
                            <Checkbox
                                name="add"
                                colorScheme="success"
                                borderColor="success.400"
                                defaultChecked={false}
                                isChecked={addChecked}
                                isDisabled={removeChecked}
                                onChange={handleClickCheck}
                            />
                        </Flex>
                    </Table.Cell>
                    <Table.Cell w="5%" textAlign="center">
                        <Flex alignItems="center" justifyContent="center" height="100%">
                            <Checkbox
                                name="remove"
                                colorScheme="error"
                                borderColor="error.400"
                                defaultChecked={false}
                                isChecked={removeChecked}
                                isDisabled={addChecked}
                                onChange={handleClickCheck}
                            />
                        </Flex>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
    );
};