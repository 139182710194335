import React from "react";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Link as RouterLink } from "react-router-dom";
import { getRoles, getToken } from "../auth/authConfig";
import { Button, Container, Navbar, LEGOLogo, Spacer, Text, Flex, Stack, Box } from "@lego/klik-ui";

export const Menu = () => {
  const { instance } = useMsal();
  
  const [roles, setRoles] = useState<string[] | undefined>(undefined);
  const [activeItem, setActiveItem] = React.useState(-1);

  useEffect(() => {
    getToken(instance).then(token => {
          setRoles(getRoles(token.accessToken));
        })
  }, [instance]);

  return (
    <Container id="navbar" className="navbar" maxWidth="100%" padding={0}>
      <Navbar bg="light-blue.800" height="auto" py="4">
        <Navbar.Brand as={RouterLink} to="/" alignItems="flex-end">
          <LEGOLogo mr="24px" />
          <Box display="flex" flexDirection="column">
            <Text as="i" fontSize="4xl" fontWeight="extrabold" textTransform="uppercase">SODA</Text>
            <Text as="i" fontSize="sm" fontWeight="bold" textTransform="uppercase" opacity="0.7">Software Device Assignment</Text>
          </Box>
        </Navbar.Brand>
        { /*
        <Navbar.Segment display={{ base: "none", lg: "flex" }}>
          { roles?.includes("Application.ReadWrite") &&
          <Navbar.Item hasMenu={true} icon={ArrowDownBold} isActive={activeItem === 0}
            title="Nodehandling">
                { roles?.includes("Application.ReadWrite") &&
                  <Navbar.Option as={RouterLink} to="/upload" onClick={() => void setActiveItem(0)}>Upload</Navbar.Option>
                }
                { (roles?.includes("Application.ReadWrite") || roles?.includes("Application.Read")) &&
                  <Navbar.Option as={RouterLink} to="/list" onClick={() => void setActiveItem(0)}>App List</Navbar.Option>
                }
          </Navbar.Item>
          }
        </Navbar.Segment>
        */ }
        <Spacer />
        { /*
        <Navbar.Segment display={{ base: "none", md: "flex" }}>
          <Button variant="solid" bg="success.400" borderColor="success.400" color="white" borderRadius={0} px={10} mx={5}>Save Changes</Button>
          <Button variant="solid" bg="warning.400" borderColor="warning.400" color="white" borderRadius={0} px={10} onClick={() => instance.logoutRedirect()}>Logout</Button>
        </Navbar.Segment>
        */ }
        <Navbar.Burger display={{ base: "block", lg: "none" }} />
      </Navbar>
    </Container>
  );
}