import React from "react";
import "./index.css";
import App from "./App";
import { createStandaloneToast, KlikProvider } from "@lego/klik-ui";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { pca, request } from "./auth/authConfig";
import LoadingComponent from "./components/LoadingComponent";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container as HTMLElement);

const { ToastContainer } = createStandaloneToast();
root.render(
  <React.StrictMode>
    <KlikProvider includeFont={true}>
      <BrowserRouter>
        <MsalProvider instance={pca}>
          <MsalAuthenticationTemplate
            authenticationRequest={request}
            interactionType={InteractionType.Redirect}
            loadingComponent={LoadingComponent}
          >
            <App />
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </BrowserRouter>
      <ToastContainer />
    </KlikProvider>
  </React.StrictMode>
);
