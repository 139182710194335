import {Checkbox, Flex, Table, Tag} from "@lego/klik-ui";
import React, {useState} from "react";
import {CombinedGroup} from "../models/CombinedGroup";
import {CombinedChecksState} from "../models/CombinedChecksState";

interface DeviceGroupRowProps {
    group: CombinedGroup;
    combinedChecks: CombinedChecksState;
    handleCheckedGroup: (checks: CombinedChecksState, group: CombinedGroup) => void;
}
export const DeviceGroupRow = ({ group, combinedChecks, handleCheckedGroup }: DeviceGroupRowProps) => {
    const [removeChecked, setRemoveChecked] = useState<boolean>(combinedChecks.remove);
    const [color, setColor] = useState<string>(combinedChecks.color || 'transparent');
    
    const handleClickCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setCombinedChecksData(name,  checked,  (newState) => {
            handleCheckedGroup(newState, group);
        })
    }


    const setCombinedChecksData = (name: string, checked: boolean, callback: (newState: CombinedChecksState) => void) => {
        setColor(prevColor => {
            let newColor: string;
            if (checked) {
                newColor = '#FFF2F2';

            } else {
                newColor = '#E8f7E6';
            }
            return newColor;
        });
        
                setRemoveChecked(prev => {
                    const newRemoveChecked = name === 'remove' ? checked : prev;
                    const newState: CombinedChecksState = {
                        required: true,
                        add: false,
                        remove: newRemoveChecked,
                        color: color,
                        availableId: group.availableId,
                        requiredId: group.requiredId
                    };
                    callback(newState);
                    return newRemoveChecked;
                });
    };
    
    return (
        (
            <Table.Row key={group.name}
                       style={{backgroundColor: color}}>
                <Table.Cell w="29%">
                    {group.name}
                </Table.Cell>
                <Table.Cell w="35%">{group.name}</Table.Cell>
                <Table.Cell w="20%" textAlign="center">
                    {group.name.toLowerCase().startsWith('c1.appdeploy.device.available.')
                        ? <Tag colorScheme="information"><Tag.Label
                            className="tag">Available</Tag.Label></Tag>
                        : group.name.toLowerCase().startsWith('c1.appdeploy.device.')
                            ? <Tag colorScheme="warning"><Tag.Label
                                className="tag">Required</Tag.Label></Tag>
                            : ''}
                </Table.Cell>
                <Table.Cell w="8%" textAlign="center">

                </Table.Cell>
                <Table.Cell w="8%" textAlign="center">
                    <Flex alignItems="center" justifyContent="center" height="100%">
                        <Checkbox
                            name="remove"
                            colorScheme="error"
                            borderColor="error.400"
                            isChecked={removeChecked}
                            onChange={(e) => handleClickCheck(e)}
                        />
                    </Flex>
                </Table.Cell>
            </Table.Row>
        )
    );
}