import React, {useEffect, useState} from "react";
import { getToken } from "../auth/authConfig";
import { useMsal } from "@azure/msal-react";
import { Groups } from "../services/Groups";
import {
    Box,
    Button,
    CloseButton,
    Flex,
    Grid,
    InlineNotification,
    Search,
    Spinner,
    Table,
    Text,
    Tooltip,
    useToast
} from "@lego/klik-ui";
import {GroupRows} from "../components/GroupRows";
import {DeviceGroupRow} from "../components/DeviceGroupRow";
import { HardDriveBold, PlusBold, MinusBold } from '@lego/klik-ui/icons';
import {CombinedGroup} from "../models/CombinedGroup";
import {CombinedChecksState} from "../models/CombinedChecksState";
import {GroupRow} from "../components/GroupRow";


interface GroupListProps {
    selectedDevice: any, 
    isDeviceMembership: boolean,
    deviceGroups: CombinedGroup[],
}



export const GroupList: React.FC<GroupListProps> = ({selectedDevice, isDeviceMembership, deviceGroups}) => {

    const { instance } = useMsal();
    const toast = useToast();

    const onCloseComplete = () => {
        console.log('Group selection has been reset.');
    };

    const [isLoading, setIsLoading] = useState(true);
    const [searchGroup, setSearchGroup] = useState<string>("");
    const [showNotification, setShowNotification] = useState(false);
    const [combinedGroups, setCombinedGroups] = useState<CombinedGroup[]>([]);
    const [combinedChecksMap, setCombinedChecksMap] = useState<Map<string, CombinedChecksState>>(new Map());
    const [isSomethingSelected, setIsSomethingSelected] = useState(false);
    const[forceUpdate, setForceUpdate] = useState<boolean>(false);
    const [selectedGroupsCount, setSelectedGroupsCount] = useState<number>(0);


    const handleForceUpdate = () => {
        setForceUpdate(!forceUpdate);
    }
    
    const handleCheckedGroup = (checks: CombinedChecksState, group: CombinedGroup) =>{

        setCombinedChecksMap(prevState => {
            const newState = new Map(prevState);
            newState.set(group.name, {
                required: checks.required,
                add: checks.add,
                remove: checks.remove,
                color: checks.color,
                availableId: group.availableId,
                requiredId: group.requiredId
            });
            return newState;
        });
        
    };
    
    
            
    const resetSelection =  () => {
        setCombinedChecksMap(new Map());
        handleForceUpdate();
    };

    const filteredGroups = combinedGroups ? combinedGroups.filter(group => 
        group.name.toLowerCase().includes(searchGroup.toLowerCase()) 
    ) : [];
    
    
    const deviceAddOrDelete = async () => {
    const groupsToAdd: string[] = [];
    const groupsToRemove: string[] = [];

    combinedChecksMap.forEach((entry) => {
        if (entry.add) {
        groupsToAdd.push(entry.availableId!);
            if (entry.required !== false) {
                groupsToAdd.push(entry.requiredId!);
            }
        } else if (entry.remove) {
            groupsToRemove.push(entry.availableId!);
            groupsToRemove.push(entry.requiredId!);
        }
    });

    await addDevicesToGroups(groupsToAdd);
    await deleteDevicesFromGroups(groupsToRemove);
    await resetSelection();
};
    
    const addDevicesToGroups = async (groupIds: any[]) => {
    try {
        const token = await getToken(instance);
        const appService = new Groups();
        if (groupIds.length === 0) {
            console.log('No groups selected.');
            return false;
        } else {
            const responses = (await Promise.all(groupIds.map(groupId =>
                appService.addDevicesToGroups(token.accessToken!, [groupId.toString()], selectedDevice)))).flat();
             if (responses.includes(200)) {
                 toastMessage('success', `${selectedDevice.length === 1? 'Device' : 'Devices'} Added`, `${selectedDevice.length === 1? 'Device has been successfully ' : 'All devices have been successfully'} added.`);
                 return true;
             }else if (responses.includes(400)) {
                 toastMessage('warning', `Unable to Add ${selectedDevice.length === 1? 'Device' : 'Devices'}`, `The selected ${selectedDevice.length === 1? 'device is' : 'devices are'} already a member of the group.`);
                 return false;
             }else{
                 toastMessage('error', 'Error', `An error occurred while adding the ${selectedDevice.length === 1? 'device' : 'devices'} to the group.`)
                 return false;
             }
        }
    } catch (error) {
        console.error(error);
        return false;
    }
};

const deleteDevicesFromGroups = async (groupIds: any[]) => {
    try {
        const token = await getToken(instance);
        const appService = new Groups();
        if (groupIds.length === 0) {
            console.log('No groups selected.');
            return false;
        } else {
            const responses = (await Promise.all(groupIds.map(groupId =>
                appService.deleteDevicesFromGroups(token.accessToken!, [groupId.toString()], selectedDevice)
            ))).flat();
            if (responses.includes(200)) {
                toastMessage('success', `${selectedDevice.length === 1? 'Device' : 'Devices'} Removed`, `All ${selectedDevice.length === 1? 'device' : 'devices'} have successfully been removed.`);
                return true;
            }else if (responses.includes(404)) {
                toastMessage('warning', `Unable to Remove ${selectedDevice.length === 1? 'Device' : 'Devices'}`, `The selected ${selectedDevice.length === 1? 'device is ' : 'devices are '} not a member of the group.`);
                return false;
            }else{
                toastMessage('error', 'Error', `An error occurred while removing the ${selectedDevice.length === 1? 'device' : 'devices'} from the group.`)
                return false;
            }
        }
    } catch (error) {
        toastMessage('error', 'Error', error instanceof Error ? error.message : String(error));
        console.error(error);
        return false;
    }
};

// Function to close the notification
const onClose = () => setShowNotification(false);

const toastMessage = (toastColor: string, toastTitle: string,  toastDescription: string) => {
    toast({
        position: 'bottom-right',
        duration: 10000,
        render: ({ onClose }) => (
            <InlineNotification variant={toastColor}>
                <InlineNotification.Content alignItems="flex-start" flexDirection="column">
                    <InlineNotification.Title>{toastTitle}</InlineNotification.Title>
                    <InlineNotification.Description>
                        {toastDescription}
                    </InlineNotification.Description>
                </InlineNotification.Content>
                <CloseButton aria-label="Close" onClick={onClose} />
            </InlineNotification>
        ),
    });
};

// Example function that might trigger showing the notification
const completeTasks = async () => {
    await deviceAddOrDelete();
    resetSelection();
    setShowNotification(true); // Show the notification on task completion
};

    useEffect(() => {
        getToken(instance).then(token => {
            const appService = new Groups();
            appService.getGroups(token.accessToken!).then(group => {
                setCombinedGroups(group)
                setIsLoading(false);
            })
        })
    }, [instance])

    useEffect(() => {
        if (combinedChecksMap.size === 0) {
            setIsSomethingSelected(false);
        }
        combinedChecksMap.forEach((value) => {
            if (value.add || value.remove ) {
                setIsSomethingSelected(true);
            }else{
                setIsSomethingSelected(false);
            }
        });
        console.log(combinedChecksMap)
    }, [combinedChecksMap]);

    useEffect(() => {
        let count = 0;
        if (combinedChecksMap.size === 0){
            setSelectedGroupsCount(0)
        }
        combinedChecksMap.forEach((value) => {
            if (value.add || value.remove) {
                count++;
            }
        })
        setSelectedGroupsCount(count);
    }, [selectedGroupsCount, combinedChecksMap]);
    
    return (
        <Flex className="groups-column" flexDirection="column" bg="light-blue.300" p={3}>
            <Grid alignItems="center" templateColumns="repeat(2, 1fr)" mb={3}>
                <Box className="groups-header">
                    <Text as="h2" textStyle="2xl" color="white">Group List</Text>
                </Box>
                <Box className="groups-reset-selection" textAlign="right">
                    <Button
                        className="savechanges"
                        size="sm"
                        mx={1}
                        bg="success.400"
                        fontWeight={700}
                        borderRadius={0}
                        isDisabled={selectedDevice.length === 0 || !isSomethingSelected}
                        onClick={async () => {
                            try {
                                await completeTasks();
                            } catch (error) {
                                console.error("An error occurred:", error);
                            }
                        }}
                    >
                        Save Changes
                    </Button>
                </Box>
            </Grid>
            <Box className="group-search" mb={4}>
                <Search
                    onChange={(value: string) => {
                        setSearchGroup(value);
                    }}
                    onEnterPressed={(value: string) => {
                        setSearchGroup(value);
                    }}
                    size="md" value={searchGroup} isDisabled={isLoading} placeholder="Search for a group"
                />
            </Box>
            <Flex className="group-list-header" flexDirection="column" mb={3}>
                <Grid alignItems="center" templateColumns="repeat(2, 1fr)">
                    <Box>
                        <Text as="h4" textStyle="lg" color="white">
                            {selectedGroupsCount} {selectedGroupsCount === 1 ? 'group' : 'groups'} selected.
                        </Text>
                    </Box>
                    <Box textAlign="right">
                        <Button
                            variant="outline"
                            size="sm"
                            color="white"
                            borderColor="white"
                            borderRadius="0"
                            onClick={() => {
                                toast({
                                    position: 'bottom-right',
                                    duration: 10000,
                                    onCloseComplete,
                                    render: ({ onClose }) => (
                                        <InlineNotification variant="info">
                                            <InlineNotification.Content alignItems="flex-start" flexDirection="column">
                                                <InlineNotification.Title>Group Selection</InlineNotification.Title>
                                                <InlineNotification.Description>
                                                    Group selection has been reset.
                                                </InlineNotification.Description>
                                            </InlineNotification.Content>
                                            <CloseButton aria-label="Close" onClick={onClose} />
                                        </InlineNotification>
                                    ),
                                });
                                resetSelection();
                            }}
                            isDisabled={!isSomethingSelected}
                        >
                            Reset
                        </Button>
                    </Box>
                </Grid>
            </Flex>

            {isLoading ? (
                <Box p={4}>
                    <Spinner color="white" mx="auto" />
                    <Text as="h4" textStyle="xl" color="white" fontWeight="bold" textAlign="center" my={2}>Loading groups...</Text>
                </Box>
            ) : (
                <Flex direction="column" p="0">
                    <Table bg="white">
                        <Table.Head>
                            <Table.Row fontWeight="bold" borderBottomWidth={1} borderColor="light-blue.300">
                                <Table.Cell w="35%">Software Name</Table.Cell>
                                <Table.Cell w="45%">Description</Table.Cell>
                                <Table.Cell w="10%" color="warning.500" textAlign="center">
                                    <Tooltip aria-label="Install Method" label="Install Method" placement="top">
                                        <HardDriveBold />
                                    </Tooltip>
                                </Table.Cell>
                                <Table.Cell w="5%" textAlign="center" color="success.400">
                                    <Tooltip aria-label="Add To Group" label="Add To Group" placement="top">
                                        <PlusBold />
                                    </Tooltip>
                                </Table.Cell>
                                <Table.Cell w="5%" textAlign="center" color="error.400">
                                    <Tooltip aria-label="Remove From Group" label="Remove From Group" placement="top">
                                        <MinusBold />
                                    </Tooltip>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Head>
                    </Table>
                    <div className={"scroll-container"}>
                        <Table bg="white">
                            {deviceGroups.length > 0 && deviceGroups
                                .sort((a, b) => {
                                    return a.name.localeCompare(b.name);
                                })
                                .map(group => {
                                    const combinedChecks = combinedChecksMap.get(group.name) || {
                                        required: true,
                                        add: false,
                                        remove: false,
                                        color: '#E8F7E6',
                                        availableId: group.availableId,
                                        requiredId: group.requiredId
                                    };

                                    return (
                                        <DeviceGroupRow
                                            key={group.name}
                                            group={group}
                                            combinedChecks={combinedChecks}
                                            handleCheckedGroup={handleCheckedGroup}
                                        />
                                    );
                                })}
                            {filteredGroups
                                .sort((a, b) => {
                                    return a.name.localeCompare(b.name);
                                })
                                .map(group => {
                                    const combinedChecks = combinedChecksMap.get(group.name) || {
                                        required: true,
                                        add: false,
                                        remove: false,
                                        color: 'transparent',
                                        availableId: group.availableId,
                                        requiredId: group.requiredId
                                    };

                                    return (
                                        <GroupRow
                                            key={group.name}
                                            group={group}
                                            combinedChecks={combinedChecks}
                                            handleCheckedGroup={handleCheckedGroup}
                                            forceUpdate={forceUpdate}
                                            handleForceUpdate={handleForceUpdate}
                                        />
                                    );
                                })}
                        </Table>
                        
                    </div>
                </Flex>
            )}
        </Flex>
    );
};